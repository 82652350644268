import { environment } from "@/src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, catchError, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this._isLoggedIn$.asObservable();
  private principal: any = { token: '', username: '', permissions: []};
  STORAGE_KEY = 'exch_auth';

  constructor(private http: HttpClient, private router: Router) { }

  login(credentials: { username: string, password: string }) {
    return this.http.post(`${environment.apiUrlLabelTraxxService}/login`, credentials)
      .pipe(
        tap((response: any) => {
          this._isLoggedIn$.next(true);
          this.principal = { token: response.token, ...response.user};
          localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.principal));
        })
      );
  }

  public isLoggedIn(): boolean {
    const token = this.getTokenDetails();
    console.log(token)
    if (token) {
        return token.exp > Date.now() / 1000;
    } else {
        return false;
    }
  }

  isAdmin() {
    return this.principal?.isAdmin;
  }

  hasPermission(requestPermissionArray: Array<string>) {
    return requestPermissionArray.some((a) => {
        return this.principal?.permissions?.some((auth: string) => auth == a)
    });
  }

  public getUserDetails() {
    const user = localStorage.getItem(this.STORAGE_KEY);
    if (user) {
      try {
        this.principal = JSON.parse(user);
      } catch(error) {
        this.routeToLoginPage(true);
      }
      return this.principal;
    }

    return {};
  }

  public getTokenDetails() {
    const user = this.getUserDetails();
    let payload;
    if (user && user != "undefined" && user.token) {
        payload = user.token.split(".")[1];
        payload = window.atob(payload);
        let p = JSON.parse(payload);
        return p;
    } else {
        return null;
    }
  }

  routeToLoginPage(clearAuthToken?: boolean) {
    if (clearAuthToken) {
        localStorage.removeItem(this.STORAGE_KEY);
    }
    // this.router.navigateByUrl('/login');
    window.location.href = '/login';
  }

  public logout() {
    localStorage.removeItem(this.STORAGE_KEY);
    this.router.navigate(['/login']);
  }

  changePassword(data: any) {
      return this.http.put(`${environment.apiUrlLabelTraxxService}/changepass`, data);
  }

  // register(userInfo: { username: string, password: string }): Observable<any> {
  //   return this.http.post<any>(`${this.apiUrl}/register`, userInfo)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  // private handleError(error: any) {
  //   console.error('An error occurred:', error);
  //   throw error;
  // }
}